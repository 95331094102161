.bm-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  font-size: 60px;
  padding: 4px;

  &-sub.sub-icon {
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    svg {
      width: 0.6em;
      height: 0.6em;
    }
  }
  &.svg-icon {
    svg {
      width: 1em;
      height: 1em;
    }
  }

  &.google-pay {
    border: 1PX solid #5F6368;
    border-radius: 60PX;
    padding: 4PX 4PX 2PX 4PX!important;
    width: 38PX!important;
    height: 15PX!important;
  }
}
